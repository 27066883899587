html, body, div#root {
  height: 100%; }

html {
  overflow-y: overlay; }

div#root {
  display: flex;
  flex-direction: column;

  div#main {
    flex-grow: 1; } }
