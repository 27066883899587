$container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 1140px);

$primary: #f2d200;
$link-color: #0065c8;
$btn-link-color: $link-color;
$input-bg: #ffffff;

$body-bg: #f0f2f5;
$body-color: #000000;

$font-weight-bold: 600;

$list-group-border-radius: 0.5rem;

@import '~bootstrap/scss/bootstrap.scss';

::selection {
  background: $primary; }
::-moz-selection {
  background: $primary; }

.section {
  border-radius: $list-group-border-radius;
  border-width: $list-group-border-width;
  border-color: $list-group-border-color;
  border-style: solid;
  background-color: $white; }

.recharts-text {
  font-family: $font-family-base;
  font-size: $font-size-base; }

table.table-sm {
  td, th {
    padding: map-get($spacers, 1) map-get($spacers, 3); } }

table {
  tbody {
    tr:last-child {
      td {
        border-bottom: 0; } } } }
